<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: "index",

  // metaInfo: {
  //   title: 'Hefei Geniss Tools Co., Ltd',
  //   meta: [
  //     {
  //       name: 'viewport',
  //       // width=device-width,height=device-height,
  //       content: 'initial-scale=1, maximum-scale=0.25,minimum-scale=0.1,user-scalable=no,shrink-to-fit=no,viewport-fit=cover,target-densitydpi=device-dpi'
  //     },
  //     {
  //       name: 'description',
  //       content: '强叔,强叔现切猪杂粥,强叔猪杂粥,广州金舌头餐饮品牌管理有限公司,惠州金舌头,惠东金舌头,聚惠厨房,广州猪杂粥,广东猪杂粥'
  //     },
  //     {
  //       name: 'keywords',
  //       content: '强叔现切猪杂粥,强叔猪杂粥,猪杂粥,强叔,金舌头,广州金舌头餐饮品牌管理有限公司,惠州金舌头,惠东金舌头,聚惠厨房,广州猪杂粥,广东猪杂粥'
  //     }
  //   ]
  // }
}
</script>
<style lang="scss">

*{
  padding: 0;
  margin: 0;
}
</style>
